<template>
<div id="Scramble">
  <div id="Scramble__v1"></div>
  <div id="Scramble__v2"></div>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Scramble',
  setup () {

    let scramble;
    let chars = '—-*#@_.,~';
    let counter = 0;
    let frameRequest;
    let frame;
    let queue;

    let phrases = [
      'Die with memories, not dreams.',
      'Whatever you do, do it well.',
      'What we think, we become.',
      'Strive for greatness. ',
      'Political correctness is tyranny with manners.',
      'I was born to make mistakes, not to fake perfection.',
      'I drink to make other people more interesting.',
    ];

    function randomChar () {
      return chars[Math.floor(Math.random() * chars.length)];
    }

    function setText (newText) {
      const oldText = scramble.innerText;
      const length = Math.max(oldText.length, newText.length);
      queue = [];

      for (let i = 0; i < length; i++) {
        const from = oldText[i] || '';
        const to = newText[i] || '';
        const start = Math.floor(Math.random() * 40);
        const end = start + Math.floor(Math.random() * 40);
        queue.push({ from, to, start, end });
      }

      cancelAnimationFrame(frameRequest);
      frame = 0;
      animate();
    }

    function animate () {
      let queue_length = queue.length;
      let complete = 0;
      let output = '';

      for (let index = 0; index < queue_length; index++) {
        let { from, to, start, end, char } = queue[index];

        if (frame >= end) {
          complete++;
          output += to;
        } else if (frame >= start) {
          if (!char || Math.random() < 0.1) {
            char = randomChar();
            queue[index].char = char;
          }
          output += `<span class="random-char">${char}</span>`;
        } else {
          output += from;
        }
      }

      scramble.innerHTML = output;

      if (complete === queue.length) {
        setTimeout(() => {
          next();
          scramble_2();
        }, 2000);
      } else {
        let scrambleDOM = document.getElementById('Scramble');
        if (scrambleDOM) {
          frameRequest = requestAnimationFrame(animate);
          frame++;
        }
      }
    }

    let position = -1;

    function scramble_2 () {
      let maxBox = document.querySelectorAll('#Scramble__v2 > p').length;

      let element_1 = document.querySelector('#textBox' + position);
      element_1 && element_1.classList.remove('active');

      position++;
      if (position === maxBox) position = 0;

      let element_2 = document.querySelector('#textBox' + position);
      element_2 && element_2.classList.add('active');
    }


    function next () {
      setText(phrases[counter]);
      counter = (counter + 1) % phrases.length;
    }


    onMounted(() => {
      for (let i = 0; i < phrases.length; i++) {
        let box = document.querySelector('#Scramble__v2');
        let sl = document.createElement('p');
        sl.className = 'sl';
        sl.id = 'textBox' + i;
        box.append(sl);
        for (let j = 0; j < phrases[i].length; j++) {
          let span = document.createElement('span');
          span.style.transition = `${Math.random() * 3}s`;
          span.style.transitionDelay = Math.random() + '';
          span.innerText = phrases[i].charAt(j);
          document.querySelector('#textBox' + i).append(span);
        }
      }

      let element = document.querySelector('#textBox' + position);
      element && element.classList.add('active');
      scramble_2();

      scramble = document.getElementById('Scramble__v1');
      next();
    });

  },
};
</script>

<style lang="stylus">
#Scramble__v1
  width 100%
  height 50%
  justify-content center
  align-items center
  display flex
  .random-char
    opacity 0.2
    font-size 25px

#Scramble__v2
  height 50%
  position relative
  flex 1
  width 100%
  .sl
    display block
    position absolute
    top 50%
    left 0
    right 0
    text-align center
    transform translateY(-50%)
  span
    opacity 0
    filter blur(20px)
  .sl.active span
    opacity 1
    filter blur(0px)
    transition 10s
  .active
    z-index 10
</style>

<style lang="stylus" scoped>
#Scramble
  position absolute
  overflow hidden
  z-index 100
  top 100px
  right 100px
  bottom 100px
  left 100px
  border-radius 20px
  font 400 28px Averta
  letter-spacing 2px
  padding 100px
  box-shadow: 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2);
  background-image: linear-gradient(#fff, #f5f5fa);
</style>